/* header and navbar */
header {
  background-color: #eff0f4;
  border-bottom: 5px solid #1c75bc;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 10px 15px 15px 0;
}

.navbar-brand img {
  max-width: auto;
}

@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width: 140px;
  }
  .navbar {
    min-height: 50px;
  }
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #1c75bc;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #f7931e;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #1c75bc;
}

a:hover, a:active {
  color: #f7931e;
}

h1, h2, h3, h4, h5, h6 {
  color: #1c75bc;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #f7931e;
  border: 1px solid #f7931e;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

main {
  padding: 30px;
}

/* blog posts */
.post {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
}

.meta {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
}

.meta i {
  color: #f7931e;
}

.author, .date {
  padding: 0 10px 0px 5px;
}

p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.post-excerpt {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
}

.post-excerpt a.button {
  font-weight: bold;
}

.post-excerpt a.button {
  text-transform: uppercase;
  color: #f7931e;
  border-bottom: 1px solid #f7931e;
  letter-spacing: .1em;
}

.pagination {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid #dddddd;
}

.pagination a {
  text-transform: uppercase;
  color: #1c75bc;
  border-bottom: 1px solid #1c75bc;
  letter-spacing: .1em;
}

.pagination a.right {
  display: inline-block;
  float: right;
}

.pagination a.left {
  display: inline-block;
  float: left;
}

.pagination a:hover {
  text-decoration: none;
  font-weight: bold;
}

/* sidebar */
aside h3.widget-title, aside h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
}

/* footer */
footer {
  background-color: #1c75bc;
  padding: 30px 0;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
}

footer .container {
  margin-left: auto;
  max-width: 85%;
}

footer a {
  color: #FFF;
  padding: 0 10px;
  border-left: 1px solid #fff;
}

footer a:hover {
  font-weight: bold;
  color: #f1f1f1;
}

footer .copyright {
  padding-right: 10px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #f7931e;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #eff0f4 !important;
  padding: 30px 0;
  text-align: center;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #1c75bc;
  font-weight: 700;
  margin: 0 auto;
  text-transform: uppercase;
}

/*hero only needed when including the hero partial*/
section.hero {
  text-align: left;
  background-color: #eff0f4;
  background-image: url(/images/cs-bkg.jpg);
  padding: 3% 0 6%;
  min-height: 300px;
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
}

section.hero .hero_message {
  text-align: right;
  margin: auto;
  padding: 1%;
  width: 100%;
  background: transparent;
}

section.hero .hero_message h1 {
  font-size: 5em;
  line-height: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
  color: #1c75bc;
}

section.hero .hero_message p {
  color: #454545;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 40px;
  width: 100%;
  text-align: right;
  margin: auto;
}

section.hero .hero_message a.read-more {
  background-color: #f7931e;
  border: 1px solid #f7931e;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 3px;
  display: inline-block;
}

@media screen and (max-width: 1000px) {
  section.hero {
    min-height: auto;
    background-position: right bottom;
    text-align: center;
  }
  section.hero .hero_message h1 {
    font-size: 2.4em;
    line-height: 1.2em;
    padding-bottom: 10px;
    margin-top: 0;
  }
  section.hero .hero_message p {
    font-size: 18px;
    padding-bottom: 30px;
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: white;
  padding: 20px 0 0;
}

section#home-search .row {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 40px;
}

section#home-search h1 {
  color: #1c75bc;
  font-size: 37px;
  line-height: 57px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}

section#home-search .divider {
  background-image: url(/images/divider.jpg);
  background-position: center;
  height: 40px;
  max-width: 50%;
  margin: 0 auto 40px;
  background-repeat: no-repeat;
}

section#home-search .icon {
  border-radius: 50%;
  background-color: #f7931e;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: auto;
  padding: 24px 0;
}

section#home-search .icon i {
  font-size: 50px;
  color: white;
  vertical-align: middle;
}

section#home-search h3 a {
  color: #1c75bc;
  padding-top: 30px;
  font-size: 1em;
  font-weight: 700;
}

section#home-search p a {
  padding-top: 20px;
  color: #454545;
  display: block;
  max-width: 80%;
  text-align: center;
  margin: auto;
  padding-bottom: 20px;
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}

aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
}

aside ul.sectors li i, aside ul.locations li i {
  color: #f7931e;
}

/* Home Posts Site Specific */
section#home-posts {
  text-align: center;
  padding: 0 0 20px;
}

section#home-posts .row {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 50px;
}

section#home-posts h1 {
  color: #1c75bc;
  font-size: 37px;
  line-height: 57px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}

section#home-posts .divider {
  background-image: url(/images/divider.jpg);
  background-position: center;
  height: 40px;
  max-width: 50%;
  margin: 0 auto 40px;
  background-repeat: no-repeat;
}

section#home-posts .post-excerpt-home .wrapper {
  border: 1px solid #ddd;
  border-top: 8px solid #eff0f4;
  padding: 10px;
  text-align: left;
  margin-bottom: 30px;
}

section#home-posts .post-excerpt-home .wrapper p.title {
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  height: 80px;
  overflow: hidden;
}

section#home-posts .post-excerpt-home .wrapper p {
  height: 120px;
  overflow: hidden;
  margin-bottom: 10px;
}

section#home-posts .post-excerpt-home .wrapper .button {
  color: white;
  background-color: #f7931e;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  display: block;
  width: 110px;
  text-align: center;
}

/* site specific before_footer*/
.before-footer {
  background-color: #eff0f4;
  color: #1c75bc;
  padding: 30px 0;
  font-size: 12px;
}

.before-footer h3 {
  color: #1c75bc;
  font-size: 18px;
  font-weight: normal;
}

.before-footer a, .before-footer p {
  color: #1c75bc;
  font-weight: 400;
  font-size: 13px;
}

.before-footer p i {
  font-size: 16px;
}
